import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import Spinner from '@johnlewispartnership/wtr-ingredients/ingredients/Spinner';

import styles from './LoadMoreButton.scss';

const LoadMoreButton = ({
  fetchMore,
  fetchMoreStartIndex,
  hasMore,
  loading,
  nextAutoFocusIndex,
  searchType,
  setAutoFocusIndex,
}) => {
  const handleClick = useCallback(() => {
    fetchMore({ start: fetchMoreStartIndex, searchType }).then(() => {
      setAutoFocusIndex?.(nextAutoFocusIndex);
    });
  }, [fetchMore, fetchMoreStartIndex, nextAutoFocusIndex, searchType, setAutoFocusIndex]);

  if (!hasMore) {
    return null;
  }

  return (
    <div className={styles.loadMoreWrapper}>
      {loading ? (
        <Spinner ariaTextActive="Loading more products" isActive />
      ) : (
        <Button
          aria-label="Load more"
          data-actiontype="load"
          data-shortdescription="Load more products"
          label="Load more..."
          theme="secondary"
          onClick={handleClick}
        />
      )}
    </div>
  );
};

LoadMoreButton.propTypes = {
  fetchMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool,
  loading: PropTypes.bool,
  nextAutoFocusIndex: PropTypes.number,
  setAutoFocusIndex: PropTypes.func,
  searchType: PropTypes.string,
  fetchMoreStartIndex: PropTypes.number,
};

LoadMoreButton.displayName = 'LoadMoreButton';

export default LoadMoreButton;
